/* Navbar styles */
.navbar {
  background-color: #333;
  height: 60px;
  display: flex;
  align-items: center;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  color: #fff;
  font-size: 1.5em;
  text-decoration: none;
}

.navbar-menu-button {
  color: #fff;
  font-size: 1.2em;
  display: none;
  cursor: pointer;
}

.navbar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-item {
  margin: 0 20px;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

/* Media queries for responsive styles */
@media (max-width: 767px) {
  .navbar-nav {
    display: none;
  }
  .navbar-menu-button {
    display: block;
  }
}/*# sourceMappingURL=navbar.css.map */