/* Set the background color of the body element */
body {
	background-color: #282a36;
}

/* Set the font family and text alignment for the App element */
.App {
	font-family: sans-serif;
	text-align: center;
}

/* Set the display and layout properties for the champion-list element */
.champion-list {
	display: flex;
	/* Make the element a flex container */
	flex-wrap: wrap;
	/* Allow the flex items to wrap onto multiple lines if necessary */
	justify-content: center;
	/* Align the flex items along the main axis */
}

/* Set the display, layout, and hover properties for the champion-card element */
.champion-card {
	display: flex;
	/* Make the element a flex container */
	flex-direction: column;
	/* Set the direction of the flex layout to column */
	align-items: center;
	/* Align the flex items along the cross axis */
	margin: 16px;
	/* Add some space around the element */
	width: 128px;
	/* Set the width of the element */
	text-align: center;
	/* Center the text within the element */
	cursor: pointer;
	/* Change the cursor to a pointer when hovering over the element */
	transition: all 0.2s ease-in-out;
	/* Add a smooth transition effect when the element is hovered */
}

/* Set the hover properties for the champion-card element */
.champion-card:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	/* Add a box shadow effect when hovering over the element */
	transform: scale(1.1);
	/* Scale the element up slightly when hovering over it */
	background-color: #6272a4;
	/* Change the background color when hovering over the element */
}

/* Set the size of the image within the champion-card element */
.champion-card img {
	width: 100px;
	height: 100px;
}

/* Set the display and layout properties for the container element */
.container {
	display: flex;
	/* Make the element a flex container */
	flex-direction: column;
	/* Set the direction of the flex layout to column */
	align-items: center;
	/* Align the flex items along the cross axis */
}

/* Set the display and layout properties for the tag-selector element */
.tag-selector {
	display: flex;
	/* Make the element a flex container */
	justify-content: center;
	/* Align the flex items along the main axis */
	margin-bottom: 20px;
	/* Add some space below the element */
}

/* Set the display, layout, and hover properties for the label element within the tag-selector element */
.tag-selector label {
	display: flex;
	/* Make the element a flex container */
	align-items: center;
	/* Align the flex items along the cross axis */
	justify-content: space-between;
	/* Distribute the flex items evenly along the main axis, with a small gap between them */
	width: 120px
}

/* Set the appearance, size, border, and hover properties for the checkbox element within the label element within the tag-selector element */
.tag-selector input[type="checkbox"] {
	appearance: none;
	/* Remove the default appearance of the checkbox */
	width: 16px;
	/* Set the width of the checkbox */
	height: 16px;
	/* Set the height of the checkbox */
	border: 2px solid #f1fa8c;
	/* Add a border to the checkbox */
	border-radius: 4px;
	/* Round the corners of the border */
	margin-right: 8px;
	/* Add some space to the right of the checkbox */
	outline: none;
	/* Remove the default outline of the checkbox */
	cursor: pointer;
	/* Change the cursor to a pointer when hovering over the checkbox */
	transition: all 0.2s ease-in-out;
	/* Add a smooth transition effect when the checkbox is hovered */
}

/* Set the background color of the checked checkbox element within the label element within the tag-selector element */
.tag-selector input[type="checkbox"]:checked {
	background-color: #f1fa8c;
}

/* Set the color and font size for the span element within the label element within the tag-selector element */
.tag-selector label span {
	color: #f1fa8c;
	font-size: 16px;
	cursor: pointer;
}

/* Set the color for the h1 element */
h1 {
	color: #f1fa8c;
}

/* Set the color and margin for the h2 element */
h2 {
	margin: 8px 0;
	color: #f1fa8c;
}

/* Set the display, layout, and margin for the champion-info element */
.champion-info {
	display: flex;
	/* Make the element a flex container */
	flex-direction: column;
	/* Set the direction of the flex layout to column */
	align-items: center;
	/* Align the flex items along the cross axis */
	margin: 32px 0;
	/* Add some space above and below the element */
	text-align: left;
	/* Align the text within the element to the left */
}

/* Set the margin for the h1 element within the champion-info element */
.champion-info h1 {
	margin-bottom: 16px;
}

/* Set the margin for the h3 element */
h3 {
	margin-top: 16px;
}

/* Remove the default list styling for the ul element */
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* Set the margin for the li element */
li {
	margin: 8px 0;
}

/* Set the margin and font size for the p element */
p {
	margin-top: 16px;
	font-size: 14px;
}